@charset "utf-8";

/*!
---- Smart Style Complex Original Stylesheet Template. ----
Thema Name:     model
Description:    model
Version:        1.0.0
Last Update:    2018/12/17
Author:         Takuya Sakamoto
Company URL:    http://ss-complex.com/
License:        GPL
License URL:    http://www.gnu.org/licenses/gpl.html
*/


// CSS設計手法は「FLOCSS」の設計思想をベースにしつつ、
// 「Atomic Design」の考え方も取り入れている。

// CSSのレイヤー構成とその優先度・読み込ませたい順は結構わけわかんなくなりがちなので、
// それを明示するために各フォルダの名前には連番を付与している。

// レイヤー構成の複雑化を避けるため、連番付きフォルダの中に子フォルダを作成しないこと。
// 連番付きフォルダの中には「_hogehoge.scss」のみを追加していく。
// ただし「styles」フォルダに新たな連番付きフォルダを追加するのはOK。

// class名の命名時には、「MindBEMding」のルールに従うこと。

// スタイル記述時のネストは原則2階層目までとする。


// ==========================================================================
// Variable
// ==========================================================================
@import "01-variable/*";
// 変数はこのフォルダの中の「_variable.scss」に全部まとめて記述する。
// ちなみに「_Reboot.scss」用に変数が必要となるため、
// 「_Reboot.scss」よりも先に「_variable.scss」を読み込んでいる。

// ==========================================================================
// Reset
// ==========================================================================
@import "02-reset/*";
// リセット系のcssはこのフォルダに入れる。
// 「Bootstrap」付属の「Reboot.css」を採用している。

// ==========================================================================
// Frameworks
// ==========================================================================
@import "03-frameworks/*";
// フレームワークに付属してくるcssはこのフォルダに入れる。

// ==========================================================================
// Utility
// ==========================================================================
@import "04-utility/*";
// パーツ未満の「汎用お助けスタイル」を記述したscssはこのフォルダに入れる。
// アイコンやロゴなど、擬似要素に使用する画像の
// ファイル読み込み部分（background-image）を記述したscssもこのフォルダに入れる。

// ==========================================================================
// Object
// ==========================================================================
@import "05-object/*";
// ボタンやカードなど、それ単体で意味のあるパーツのスタイルを
// 記述したscssはこのフォルダに入れる。

// ==========================================================================
// Layout
// ==========================================================================
@import "06-layouts/*";
// contentBoxやflexBox、marginなど、
// 要素の位置を調整するための汎用スタイルを記述したscssはこのフォルダに入れる。

// ==========================================================================
// Template
// ==========================================================================
@import "07-template/*";
// html文書の雛形。
// ヘッダーとフッターのスタイルを記述したscssはこのフォルダに入れる。

// ==========================================================================
// Pages
// ==========================================================================
@import "08-pages/*";
// ページごとに異なる部分のスタイルを記述したscssはこのフォルダに入れる。
// 必ずページ名単位でscssファイルを割ること。

// ==========================================================================
// Template
// ==========================================================================
@import "template/*";
// 見出しやリスト、テーブルなど、Webサイトの構成要素として
// 定番のパーツ群のテンプレートscssをあらかじめこのフォルダに用意している。
// プロジェクトのデザインによって適宜改変する必要アリ。
